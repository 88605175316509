<script setup lang="ts">
import spacing from '#tailwind-config/theme/spacing'

import type Spacing from '~/types/spacing'
import type { StyleRecord } from '~/types/styleRecord'

type Props = {
  padding?: Spacing
}
type Slots = {
  default(): any
}

const props = withDefaults(defineProps<Props>(), {
  padding: '5',
})
defineSlots<Slots>()

const computedStyle = computed<StyleRecord<'padding'>>(() => ({
  padding: spacing[props.padding],
}))
</script>

<template>
  <div
    class="vCardContainer tw-rounded-sm tw-bg-white tw-shadow tw-transition-shadow tw-duration-150"
    :style="computedStyle"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.hasHover:hover {
  @apply tw-cursor-pointer;
  @screen lg {
    @apply tw-shadow-hover;
  }
}
.noShadow {
  @apply tw-shadow-none;
}
</style>
